<template>
  <div class="StatisticsBussinessList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      :hasOperateColumn="false"
      :searchVisible="false"
    >
      <template #btnSlot="scope">
        <v-button text="合计查询" type="success" @click="search(1)"/>
        <v-button text="明细搜索" type="success" @click="search"/>
      </template>
      <template #searchSlot>
        <v-datepicker
          label="时间"
          :startTime.sync="searchParams.startDate"
          :endTime.sync="searchParams.endDate"
          :clearable="false"
          :minDate="minDate"
          :maxDate="new Date()"
        ></v-datepicker>
        <v-input label="商品名称" v-model="searchParams.goodsName"></v-input>
        <v-select2 label="所属项目" v-model="searchParams.communityId" v-bind="communityParams"></v-select2>
        <v-input label="商家名称" v-model="searchParams.busName"></v-input>
        <v-input label="商家整合方" v-model="searchParams.integrationName" :width="160"></v-input>
        <v-select2 label="所属公司" v-model="searchParams.regionId" v-bind="regionParams" :width="160"></v-select2>
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" permission="update" @click="edit(scope.row)"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import { communityParams, regionParams } from 'common/select2Params'
import { getListURL, exportListURL } from './api'
import moment from 'moment'

export default {
  name: 'StatisticsBussinessList',
  data () {
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      communityParams,
      regionParams,
      minDate: moment().subtract(1, 'y').format('YYYY-MM-DD'),
      searchParams: {
        startDate: moment().subtract(30, 'd').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        goodsName: '',
        communityId: '',
        busName: '',
        integrationName: '',
        regionId: ''
      },
      headers: [
        {
          prop: 'goodsName',
          label: '商家产品名称'
        },
        {
          prop: 'busName',
          label: '商家名称'
        },
        {
          prop: 'integrationName',
          label: '商家整合方'
        },
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'regionName',
          label: '所属公司'
        },
        {
          prop: 'viewDistinctCount',
          label: '详情浏览人数(去重)',
          align: 'right'
        },
        {
          prop: 'viewCount',
          label: '浏览次数',
          align: 'right'
        },
        {
          prop: 'orderDistinctCount',
          label: '下单人数(去重)',
          align: 'right'
        },
        {
          prop: 'orderCount',
          label: '下单次数',
          align: 'right'
        }
      ]
    }
  },
  methods: {
    search (val) {
      if (val === 1) {
        this.searchUrl = `${getListURL}?isTotal=true`
      } else {
        this.searchUrl = getListURL
      }
      this.$nextTick(() => {
        this.$refs.list.searchData()
      })
    }
  }
}
</script>
